
import MortgageByBFE from "~/graphql/Property/MortgageByBFE.gql";
import { group_digits_in_number, capitalize_first_letter } from "~/plugins/common-fns.js";

export default {
  name: "OwnerListItem",

  props: {
    owner: {
      type: Object,
      default: () => {},
    },

    startsExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      hasLoaded: false,
      expanded: this.startsExpanded,
      mortgages: [],
      headers: [
        { text: this.$t("OWNERS_C_DEBITOR"), value: "debitors[0].name", sortable: true },
        { text: this.$t("OWNERS_M_NUMBER"), value: "number", sortable: true },
        { text: this.$t("OWNERS_M_PRIO"), value: "priority", sortable: true },
        { text: this.$t("OWNERS_M_TYPE"), value: "chargeType", sortable: true },
        { text: this.$t("OWNERS_M_HSTOL"), value: "amount", sortable: true },
        { text: this.$t("OWNERS_C_INT"), value: "rate", sortable: true },
        { text: this.$t("OWNERS_C_INTTYPE"), value: "rateType", sortable: true },
        { text: this.$t("OWNERS_C_CREDITOR"), value: "creditors[0].name", sortable: true },
      ],
    };
  },

  watch: {
    loading: function (val) {
      if (!val) {
        this.notifyMortagesLoaded();
      }
    },
  },

  mounted() {
    if (this.startsExpanded) {
      this.getMortgages();
    }
  },

  methods: {
    capitalize_first_letter: capitalize_first_letter,
    group_digits_in_number: group_digits_in_number,

    expandClicked() {
      if (this.mortgages && this.mortgages.length == 0 && !this.loading && !this.hasLoaded) {
        this.getMortgages();
      }
      this.expanded = !this.expanded;
    },

    formatDocumentId(documentId) {
      var firstDashIndex = documentId.indexOf("-");
      var date = documentId.substring(0, firstDashIndex);
      var formattedDate = this.$moment(date).format("DD.MM.yyyy");
      var id = documentId.substring(firstDashIndex, documentId.length);
      return formattedDate + id;
    },

    getChargeType(item) {
      if (item.description.includes("pantebrev")) {
        return this.capitalize_first_letter(item.description);
      }
      return this.capitalize_first_letter(item.chargeType);
    },

    async getMortgages() {
      this.loading = true;
      const resp = await this.$apollo.query({
        query: MortgageByBFE,
        variables: {
          bfeNumber: parseInt(this.owner.bfeNumber),
        },
      });
      this.mortgages = resp.data.searchByBFE?.charges;
      this.loading = false;
      this.hasLoaded = true;
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    notifyMortagesLoaded() {
      const numberOfMortgages = this.mortgages.length;

      const mortagesBreakdown = {};

      this.mortgages.forEach((mortgage) => {
        if (!mortgage.chargeType) {
          return;
        }

        if (!mortagesBreakdown[mortgage.chargeType]) {
          mortagesBreakdown[mortgage.chargeType] = {
            chargeType: mortgage.chargeType,
            amount: 0,
          };
        }

        mortagesBreakdown[mortgage.chargeType].amount += parseInt(mortgage.amount, 10);
      });

      this.$emit("mortgages-loaded", {
        numberOfMortgages: numberOfMortgages,
        mortagesBreakdown: mortagesBreakdown,
      });
    },
  },
};
