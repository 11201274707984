
import { getPropertyOwnersQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import PropertyMortgages from "../components/mainproperty/PropertyMortgages.vue";
import CondoMortgages from "../components/condo/CondoMortgages.vue";

export default {
  title() {
    return `${this.$t("MENU_MORTGAGES")}`;
  },
  apollo: {
    property: getPropertyOwnersQuery,
  },
  components: {
    PropertyMortgages,
    CondoMortgages,
  },
  computed: {
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading;
    },
    isProperty: function () {
      return this.property.plots != null;
    },
    isCondo: function () {
      return this.property.condo != null;
    },
  },
};
