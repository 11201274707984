
import { getPropertyCondosQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import OwnerListItem from "../OwnerListItem";
import { sortByAddress } from "~/helpers/sort-helpers.js";
import { getAddressAsShortString } from "~/helpers/address-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";

export default {
  name: "CondoMortgages",
  components: {
    OwnerListItem,
  },
  apollo: {
    condos: getPropertyCondosQuery,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      activeId: 0,
      mortagesBreakdown: {
        series: [
          {
            data: [],
          },
        ],
      },
      numberOfMortgages: 0,
      exampleData: {
        series: [
          {
            data: [
              {
                x: "Realkreditpantebrev",
                y: 557000,
              },

              {
                x: "Ejerpantebrev",
                y: 150000,
              },
            ],
          },
        ],
      },
    };
  },

  computed: {
    owners() {
      const condoBFEs =
        this.condos
          ?.flatMap((x) => x.units)
          .map((x) => {
            return { bfeNumber: x.bfeNumber, address: x.unit?.address || null };
          }) || [];
      const propertyBFE = {
        bfeNumber: this.property.bfeNumber,
        address: this.getAddress(this.property),
      };
      const result = [propertyBFE, ...condoBFEs];
      return result.sort(sortByAddress);
    },
  },

  methods: {
    getUnitAddress(id) {
      return getAddressAsShortString(id) || this.$t("NO_ACCESS_ADDRESS");
    },

    setActiveId(id) {
      this.activeId = id;
    },

    getAddress(property) {
      return getPropertyAddress(property);
    },

    setMortgageFigures(info) {
      this.numberOfMortgages = info.numberOfMortgages;
      this.mortagesBreakdown = {
        series: [
          {
            data: Object.values(info.mortagesBreakdown).map((x) => {
              return {
                x: x.chargeType,
                y: x.amount,
              };
            }),
          },
        ],
      };
    },
  },
};
