// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".marginBottomAdjust_c8U27{display:flex;margin-top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottomAdjust": "marginBottomAdjust_c8U27"
};
module.exports = ___CSS_LOADER_EXPORT___;
